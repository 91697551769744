import CopyrightFooter from "./CopyrightFooter";
import FooterContent from "./FooterContent";
import useTranslation from 'next-translate/useTranslation';

const Index = ({ footerStyle = "" }) => {
  const { t } = useTranslation('common');
  return (
    <footer className={`main-footer ${footerStyle}`}>
      <div className="auto-container">
        {/* <!--Widgets Section--> */}
        <div className="widgets-section">
          <div className="row">
            <div className="big-column col-xl-4 col-lg-3 col-md-12">
              <div className="footer-column about-widget">
                <div className="logo">
                  <a href="#">
                    <img src="/images/logo.svg" alt="brand" />
                  </a>
                </div>
                <p className="phone-num">
                  <span>{t('header.contact')} </span>
                  <a href="tel:+48537575739">+48&nbsp;537&nbsp;575&nbsp;739</a>
                </p>
                <p className="email">
                  <a href="mailto:kontakt@headhunti.pl">
                    kontakt@headhunti.pl
                  </a>
                </p>
                <p className="address">
                  Grzybowska 60
                  <br />00-844 Warszawa<br /><br />
                </p>
                <p className="address">
                  Wyspa Słodowa 7
                  <br />50-266 Wrocław<br />
                </p>
              </div>
              <a
                href=""
                className="theme-btn btn-style-seven call-modal"
                data-bs-toggle="modal"
                data-bs-target="#inquiryPopupModal"
              >
                {t('button.offer')}
              </a>
            </div>
            {/* End footer left widget */}

            <div className="big-column col-xl-8 col-lg-9 col-md-12">
              <div className="row">
                <FooterContent />
              </div>
            </div>
            {/* End col-xl-8 */}
          </div>
        </div>
      </div>
      {/* End auto-container */}

      <CopyrightFooter />
      {/* <!--Bottom--> */}
    </footer>
    //   {/* <!-- End Main Footer --> */}
  );
};

export default Index;
